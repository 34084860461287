'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
var $ = require("jquery");
var navicon_1 = require("./class/navicon");
var smooth_scroll_1 = require("./class/smooth-scroll");
var slick_1 = require("./class/slick");
var news_1 = require("./class/news");
var gallery_1 = require("./class/gallery");
$(function () {
    new navicon_1.default;
    new smooth_scroll_1.default;
    new slick_1.default();
    new news_1.default();
    new gallery_1.default();
});
