'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.Slick = void 0;
var $ = require("jquery");
require("slick-carousel");
var Slick = /** @class */ (function () {
    function Slick() {
        this.breakpoint = 480;
        $('.bulletSlick').slick({
            autoplay: true,
            fade: true,
            autoplaySpeed: 3000,
            speed: 1500,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            dotsClass: 'slick-dots',
            arrows: true,
            prevArrow: '<img src="/shared/img/dist/common/arrow@2x.png" class="slick-arrow slick-arrow--left">',
            nextArrow: '<img src="/shared/img/dist/common/arrow@2x.png" class="slick-arrow slick-arrow--right">',
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        arrows: false,
                        dots: false,
                    }
                }
            ]
        });
        $('.bulletSlickGallery').slick({
            autoplay: false,
            fade: false,
            // autoplaySpeed: 3000,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            dotsClass: 'slick-dots slick-dots-gallery',
            arrows: true,
            prevArrow: '<img src="/shared/img/dist/common/arrow-bk@2x.png" class="slick-arrow slick-arrow-gallery slick-arrow-gallery--left">',
            nextArrow: '<img src="/shared/img/dist/common/arrow-bk@2x.png" class="slick-arrow slick-arrow-gallery slick-arrow-gallery--right">',
            responsive: [
                {
                    breakpoint: 480,
                    settings: {
                        arrows: true,
                        dots: false,
                        prevArrow: '<img src="/shared/img/dist/common/arrow@2x.png" class="slick-arrow slick-arrow-gallery slick-arrow-gallery--left">',
                        nextArrow: '<img src="/shared/img/dist/common/arrow@2x.png" class="slick-arrow slick-arrow-gallery slick-arrow-gallery--right">',
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        arrows: true,
                        dots: true,
                    }
                },
            ]
        });
    }
    return Slick;
}());
exports.Slick = Slick;
exports.default = Slick;
