'use strict';
Object.defineProperty(exports, "__esModule", { value: true });
exports.Gallery = void 0;
var $ = require("jquery");
var Gallery = /** @class */ (function () {
    function Gallery() {
        $('.trigGalleryImage').on('click', function (e) {
            var src = $(e.currentTarget).children().attr('src');
            $('.bulletGalleryImage').attr('src', src);
            setTimeout(function () {
                $('.bulletGalleryModal').fadeIn('slow');
            }, 500);
        });
        $('.trigCloseGallery').on('click', function () {
            $('.bulletGalleryModal').fadeOut('slow');
        });
    }
    return Gallery;
}());
exports.Gallery = Gallery;
exports.default = Gallery;
